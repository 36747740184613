<template>
  <div :key="notification.id" v-for="(notification, index) in notificationsToConfirm">
    <ibiola-dialog :title="notification.title" :sub-line="notification.content" :subMarkdown="true" :open="index === 0"
                   :primary-button-label="t('customer-notifications-label-read')" @primary="markAsRead(notification)"
                   :allowClose="false" :isLoading="loadingConfirm"></ibiola-dialog>
  </div>
  <div class="h-screen flex overflow-hidden bg-white">
    <!-- Static sidebar for desktop -->
    <div :class="{ hidden: isMobileView, 'flex flex-shrink-0': !isMobileView }">
      <div class="flex flex-col" style="width: 104px">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex flex-col flex-grow border-r border-gray-200 bg-primary-500 overflow-y-auto">
          <div class="h-top-bar flex items-center p-1 bg-gray-200">
            <img class="h-full object-scale-down" :src="logoUrl" alt="Logo" rel="preload"/>
          </div>
          <div class="mt-1 flex-grow flex flex-col">
            <nav class="flex-1 px-2 pt-5 bg-primary-500 space-y-1">
              <div v-for="item in navigation" :key="item.name" class="group">
                <!-- TODO Line break between icon and label - see figma -->
                <router-link active-class="bg-primary-600 text-white" :to="{ name: item.routeName }"
                             class="text-primary-50 group-hover:bg-primary-600 group-hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                  <div v-if="item.routeName !== 'notifications'" class="flex flex-col w-full items-center">
                    <font-awesome-icon :icon="[item.iconStyle, item.icon]" :class="[
                      item.current
                        ? 'text-white'
                        : 'text-primary-50 group-hover:bg-primary-600 group-hover:text-white',
                      'flex-shrink-0 h-6 w-6',
                    ]" style="width: 20px"/>
                    <ibiola-text :text="item.name" :type="'menu'" class="text-center"></ibiola-text>
                  </div>
                  <div v-else class="flex flex-col items-center w-full">
                    <div class="relative">
                      <font-awesome-icon :icon="[item.iconStyle, item.icon]" :class="[
                        item.current
                          ? 'text-white'
                          : 'text-primary-50 group-hover:bg-primary-600 group-hover:text-white',
                        'flex-shrink-0 h-6 w-6',
                      ]" style="width: 20px"></font-awesome-icon>
                      <div v-if="notificationsCount"
                           class="absolute top-0 right-0 bg-red-500 rounded-full flex justify-center items-center"
                           style="transform: translate(50%, -50%); width: 18px; height: 18px;">
                        <span class="text-xs text-white">
                          {{ notificationsCount }}
                        </span>
                      </div>
                    </div>
                    <ibiola-text :text="item.name" :type="'menu'" class="text-center"></ibiola-text>
                  </div>
                </router-link>

              </div>
            </nav>
          </div>

          <!-- Logout -->
          <div class="group px-2 space-y-1 bg-primary-500 pb-4">
            <router-link active-class="bg-primary-600 text-white" :to="{ name: 'logout' }"
                         class="text-primary-50 group-hover:bg-primary-600 group-hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
              <div class="flex flex-col w-full">
                <div class="text-center">
                  <font-awesome-icon :icon="['fas', 'power-off']"
                                     :class="'text-primary-50 group-hover:bg-primary-600 group-hover:text-white flex-shrink-0 h-6 w-6'"
                                     style="width: 20px"/>
                </div>
                <div class="text-center">
                  <ibiola-text :text="t('auth-logout')" :type="'menu'"></ibiola-text>
                </div>
              </div>
            </router-link>
            <!-- EO Logout -->
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white"></div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <main class="flex-1 relative overflow-y-auto overflow-x-hidden focus:outline-none bg-gray-100">
        <IbiolaLoadingSpinner v-if="loading" class="py-6"/>
        <router-view v-else></router-view>
      </main>
      <!-- Mobile Navigation -->
      <!-- TODO ICONS aktiver Link mit solid icon style -->
      <div v-if="isMobileView"
           class="pt-2 sticky bottom-0 w-full bg-white text-center text-gray-500 border-t border-gray-200 grid grid-cols-5"
           :class="{ visible: isMobileView, invisible: !isMobileView }" style="height: 65px">
        <div v-for="item in navigation" :key="item.name">
          <!--          <router-link
                        :to="{ name: item.routeName}"
                        :class="[item.current ? 'text-primary-500' : 'text-gray-500', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']"
                    > -->
          <router-link active-class="text-primary-500" :to="{ name: item.routeName }"
                       class="group flex items-center px-2 py-2 text-sm font-medium rounded-md">
            <div class="flex flex-col w-full">
              <div class="text-center">
                <!--              <font-awesome-icon
                                  :icon="[item.iconStyle, item.icon]"
                                  :class="[item.current ? 'text-primary-500' : 'text-gray-500', 'flex-shrink-0']"
                                  style="height: 20px; width: 20px;"
                              />  -->
                <font-awesome-icon :icon="[item.iconStyle, item.icon]" style="height: 20px; width: 20px"/>
              </div>
              <div class="text-center">
                <ibiola-text :text="item.name" :type="'menu'"></ibiola-text>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <!-- EO Mobile Navigation -->
    </div>
  </div>
</template>

<script>
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import {useI18n} from "vue-i18n";

import IbiolaText from "@/components/Items/Text";
import IbiolaLoadingSpinner from "../components/Layouts/LoadingSpinner";
import useBreakpoints from "@/composables/Layout/useBreakpoints";
import useUser from "@/composables/User/useUser";
import useProvider from "@/composables/Provider/useProvider";
import {useStore} from "vuex";
import useValues from "@/composables/Provider/useValues";
import {useToast} from "vue-toastification";
import IbiolaDialog from "@/components/Layouts/Dialog.vue";
import useTranslations from "@/composables/General/useTranslations";
import {getMessaging, onMessage} from "firebase/messaging";
import {initializeApp} from "firebase/app";

export default {
  name: "layout-default",
  components: {
    IbiolaDialog,
    IbiolaText,
    IbiolaLoadingSpinner,
  },
  methods: {
    // Hide Navigation on new-booking Wizard
    showMobileNavigation() {
      if (this.$route.name !== "new-date-booking") {
        // console.log("MOBILE: true " +  this.$route.path)
        return true;
      } else {
        // console.log("MOBILE: false" +  this.$route.path)
        return false;
      }
    },
  },
  setup() {
    const sidebarOpen = ref(false);
    const {t} = useI18n();
    const {isMobileView} = useBreakpoints();
    const loadTranslations = useTranslations();
    const {setNotificationRead, loadNotifications, refreshAuthenticated} = useUser();
    const {loadValues} = useValues();
    const store = useStore();
    const toast = useToast();

    const {logoUrl} = useProvider();
    const notificationsCount = computed(() => store.getters.getNotificationsCount);
    const notifications = computed(() => JSON.parse(JSON.stringify(store.getters.getNotifications)));
    const notificationsToConfirm = ref([]);
    const loadingConfirm = ref(false);
    let intervalId = null;
    const firebaseConfig = {
      apiKey: process.env.VUE_APP_FCM_API_KEY,
      authDomain: process.env.VUE_APP_FCM_AUTH_DOMAIN,
      projectId: process.env.VUE_APP_FCM_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FCM_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FCM_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_FCM_APP_ID,
      measurementId: process.env.VUE_APP_FCM_MEASUREMENT_ID
    };
    const firebase = initializeApp(firebaseConfig);
    const messaging = getMessaging(firebase);

    onMessage(messaging, (payload) => {
      console.log('push9 Message received. ', payload);
      // ...
    });


    const notificationsFunc = () => {
      if (notifications.value?.length > 0) {
        notificationsToConfirm.value = notifications.value.filter((notification) => {
          return !!(!notification.read && notification.requiresConfirmation);
        })
      }
    };

    watch(() => notificationsCount.value, () => {
      notificationsFunc()
    });

    const markAsRead = (notification) => {
      loadingConfirm.value = true
      setNotificationRead(notification.id)
        .then(() => {
          notificationsToConfirm.value.shift();
          loadingConfirm.value = false
        })
        .catch(() => {
          toast.error(t('customer-notifications-error-setRead'));
          loadingConfirm.value = false
        });
    };

    const getNotifications = () => {
      loadNotifications();
    }

    const loading = ref(true);
    onMounted(() => {
      store.commit('deleteExpiredPreCheckImages');
      loading.value = true;

      if (store.getters.getAppVersion !== process.env.VUE_APP_VERSION) {
        store.commit('invalidateLangCache');
      }
      store.commit('refreshVersion');


      let user = store.getters.getUser
      if (user && user.details && user.details.language) {
        loadTranslations(user.details.language)
      }

      if (store.getters.hasValues) {
        loading.value = false;
      }

      refreshAuthenticated();
      loadValues().then(() => (loading.value = false));

      notificationsFunc();
      getNotifications();
      intervalId = setInterval(getNotifications, 600000);
    });

    onUnmounted(() => {
      // Clear the interval when the component is unmounted

      if (intervalId) {
        clearInterval(intervalId);
      }
    });

    const navigation = computed(() => {
      const navItems = [
        {
          name: t('navigation-home'),
          icon: "location-arrow",
          iconStyle: "far",
          routeName: "dashboard",
          current: true,
        },
        {
          name: t('navigation-bookings'),
          icon: "rectangle-list",
          iconStyle: "far",
          routeName: "booking-list",
          current: false,
        },
        {
          name: t('trip-logbook-short'),
          link: "/logbook/",
          icon: "book-open",
          iconStyle: "far",
          routeName: "logbook",
          current: false,
        },
        {
          name: t('navigation-profile'),
          icon: "user",
          iconStyle: "far",
          routeName: "profile",
          current: false,
        },
        {
          name: t('navigation-help'),
          icon: "circle-question",
          iconStyle: "far",
          routeName: "help",
          current: false,
        },
      ];
      // Check if it's not a mobile view, and add the notifications item
      if (!isMobileView.value) {
        navItems.push({
          name: t('navigation-notifications'),
          icon: "bell",
          iconStyle: "fas",
          routeName: "notifications",
          current: false,
        });
      }

      return navItems;
    });


    const userNavigation = [
      {name: "Your Profile", href: "#"},
      {name: "Settings", href: "#"},
      {name: "Sign out", href: "#"},
    ];

    return {
      t,
      isMobileView,
      navigation,
      userNavigation,
      sidebarOpen,
      logoUrl,
      loading,
      notificationsCount,
      notificationsToConfirm,
      markAsRead,
      loadingConfirm
    };
  },
};
</script>

<style scoped lang="scss"></style>
